import React, { Component } from 'react';
import env from "react-dotenv";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = { imgLink: ""};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  updateWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    let link = (width > height) ? `${env.AWS_BUCKET_URL}/services/services_landscape.png` : `${env.AWS_BUCKET_URL}/services/services_portrait.png`;
    this.setState({imgLink: link});
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  render() {
    return (
        <div id="services" className="wrapper">
          <div className="img-div">
          <img className="image" src={this.state.imgLink} alt="Services" />
          </div>
        </div>
    );
  }
}

