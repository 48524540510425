import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';

import AboutUs from "./components/about-us"
import Services from "./components/services";
import Clients from "./components/clients";
import Portfolio from "./components/portfolio";
import { Contact } from "./components/contact";

import React, { useState } from 'react';

function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setTimeout(() => {setShow(false)}, 500);
  }
  const handleShow = () => setShow(true);
 
  return (
  <div>
      <Navbar bg="white" variant="light" fixed="top" expand={false} className="navbar">
          <Container fluid >
            <Navbar.Brand href="/" className="mx-auto nav-brand">
            <img
              src="./logo.png"
              className="d-inline-block align-center nav-logo text-center"
              alt="VICTOR ARZATE DESIGN INTERNATIONAL logo"
            />
            </Navbar.Brand>
      
            < Navbar.Toggle aria-controls="offcanvasNavbar" className="nav-button" onClick={handleShow}/>
            <Navbar.Offcanvas
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="bottom"
              collapseOnSelect="true"
              show={show}
              onHide={handleClose}
            >
              <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel" className="mx-auto"></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body id="offcanvasBody">
                <Nav className="justify-content-around text-center">
                  <Nav.Link href="#about-us" className="nav-link canvas-link" onClick={handleClose}>ABOUT US</Nav.Link>
                  <Nav.Link href="#services" className="nav-link canvas-link" onClick={handleClose}>SERVICES</Nav.Link>
                  <Nav.Link href="#clients" className="nav-link canvas-link" onClick={handleClose}>CLIENTS</Nav.Link>
                  <Nav.Link href="#portfolio" className="nav-link canvas-link" onClick={handleClose}>PORTFOLIO</Nav.Link>
                  <Nav.Link href="#contact" className="nav-link canvas-link" onClick={handleClose}>CONTACT</Nav.Link>
                </Nav>
              </Offcanvas.Body>
              </Navbar.Offcanvas>
          </Container>
        </Navbar>
    <AboutUs />
    <Services />
    <Clients />
    <Portfolio />
    <Contact />
  </div>
 );
}
 
export default App;