import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 

export const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_stn3a68', 'contact_form', form.current, 'user_cfxY2mLAVQcNWEYW9XsxI')
      .then((result) => {
        console.log(result.text);
        alert("Your message is sent successfully. We will contact you with the email provided. Please make sure it is correct. Thank you.")
      }, (error) => {
        console.log(error.text);
      });
  };

    return (
      <div id="contact" className="wrapper">
        <Container>
          <Row>
            <Col md={12} lg={6} >
            <h3 align="center"><b>We Would Like to Hear from You</b></h3>
            <form ref={form} onSubmit={sendEmail}>
              <div className="input-box">
                <label id="name-label">NAME</label>
                <input type="text" name="user_name" required className="input-field"/>
              </div>
              <div className="input-box">
                <label id="company-label">COMPANY</label>
                <input type="text" name="company" required className="input-field"/>
              </div>
              <div className="input-box">
                <label id="email-label">EMAIL</label>
                <input type="email" name="user_email" required className="input-field"/>
              </div>
              <div className="input-box">
                <label id="brochure-label">WOULD YOU LIKE A VADI DESIGN BROCHURE?</label>
                <input type="text" name="brochure" required className="input-field"/>
              </div>
              <div className="input-box">
                <label id="message-label">MESSAGE</label>
                <textarea name="message" required className="input-field"/>
              </div>
              <div>
              <button type="submit" class="btn btn-secondary">SEND</button>
              </div>
            </form>
            </Col>
            <Col md={12} lg={6} align="center">
            <h3><b>Our Offices</b></h3>
            <h4><b>HONG KONG HEADQUARTERS</b></h4>
            <p>
              hongkong@vadiltd.com
            </p>
            <p>
              11/B Wing Cheong Commercial Building<br></br>
              19-25 Jervois Street<br></br>
              Sheung Wan, Hong Kong
            </p>
            <br></br>
            <h4><b>LOS ANGELES</b></h4>
            <p>losangeles@vadiltd.com</p>
            <br></br>
            <h4><b>INDIA</b></h4>
            <p>india@vadiltd.com</p>
            <br></br>
            <p>www.vadiltd.com</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
}
