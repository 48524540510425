import React, { Component } from 'react';
import env from "react-dotenv";
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

class Display extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coverLink: "" 
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  updateWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    let link = (width > height) ? this.props.landscape : this.props.portrait;
    this.setState({coverLink: link});
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.updateWindowDimensions();
    }
  }
  
  render() {
    return (  
      <div id={this.props.name} className="profolio-display">
        <div className="img-div portfolio-cover">
          <img className="image" src={this.state.coverLink} alt={`${this.props.name} cover`} />
        </div>
        {this.props.imageDisplay}
      </div>
    );
  }
}

export default class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      name: "",
      next: "",
      landscape: "",
      portrait: "",
      imageDisplay: []
    };
    this.updateCategory = this.updateCategory.bind(this);
  }
  
  updateCategory(e = "branding") {
    console.log("run update category")
    let imageNum = {
      branding: 17,
      tabletop: 2,
      uniform: 2, 
      art: 1, 
      wayfinding: 20,
      digital: 2 
    }
    
    let numArray = Array.from({length: imageNum[e]}, (_, i) => i + 1)
  
    let display = numArray.map(i => 
      (
      <div className="img-div">
          <img className="image" src={`${env.AWS_BUCKET_URL}/portfolio/${e}/show/${e}_${i}.png`} alt={`${e} ${i}`} />
      </div>
      )
    );
    let next;
    switch(e) {
      case "branding":
        next = "tabletop";
        break;
      case "tabletop":
        next = "uniform";
        break;
      case "uniform":
        next = "art";
        break;
      case "art":
        next = "wayfinding";
        break;
      case "wayfinding":
        next = "digital";
        break;
      case "digital":
        next = "digital";
        break;
    }
    this.setState({
      name: e,
      next,
      landscape: `${env.AWS_BUCKET_URL}/portfolio/${e}/${e}_landscape.png`,
      portrait: `${env.AWS_BUCKET_URL}/portfolio/${e}/${e}_portrait.png`,
      imageDisplay: display
    });
  }

  componentDidMount() {
    this.updateCategory();
  }

  render() {
    return (this.state.name !== "digital")? (
      <div id="portfolio">
        <Container>
        <Nav onSelect={this.updateCategory} className="justify-content-center portfolio-nav">
          <Nav.Item>
            <Nav.Link eventKey="branding">BRAND EXPERIENCE</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="tabletop">TABLE TOP DESIGN</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="uniform">UNIFORM DESIGN</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="art">ART & ACCESSORIES</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="wayfinding">WAYFINDING/ SIGNAGE</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="digital">DIGITAL</Nav.Link>
          </Nav.Item>
        </Nav> 
        </Container>
        <Display name={this.state.name} landscape={this.state.landscape} portrait={this.state.portrait} imageDisplay={this.state.imageDisplay}/>
        <Nav onSelect={this.updateCategory} className="justify-content-center bottom-nav">
          <Nav.Item>
            <Nav.Link href="#portfolio" eventKey={this.state.next}><i class="fas fa-chevron-right"></i></Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      ): (
        <div id="portfolio">
        <Container>
        <Nav onSelect={this.updateCategory} className="justify-content-center portfolio-nav">
          <Nav.Item>
            <Nav.Link eventKey="branding">BRAND EXPERIENCE</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="tabletop">TABLE TOP DESIGN</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="uniform">UNIFORM DESIGN</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="art">ART & ACCESSORIES</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="wayfinding">WAYFINDING/ SIGNAGE</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="digital">DIGITAL</Nav.Link>
          </Nav.Item>
        </Nav> 
        </Container>
        <Display name={this.state.name} landscape={this.state.landscape} portrait={this.state.portrait} imageDisplay={this.state.imageDisplay}/>
      </div> 
      );
      
  }
}