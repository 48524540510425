import React, { Component } from 'react';
import env from "react-dotenv";

export default class Clients extends Component {

  render() {
    return (
      <div id="clients" className="wrapper">
      <div className="img-div">
      <img className="image" src={`${env.AWS_BUCKET_URL}/clients/clients.png`} alt="clients logos" />
      </div>
    </div>
    );
  }
}